@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

body {
  @apply text-neutral-darker;
}

:root {
  --section-width: 1180px;
  color-scheme: light only;
}

@screen 2xl {
  :root {
    --section-width: 1280px;
  }
}

.text-title {
  @apply text-3xl lg:text-5xl;
}

.text-title-sm {
  @apply text-3xl lg:text-4xl;
}
